@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom animation for fading in with downward movement */
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-down {
  animation: fade-in-down 1s ease-out forwards;
}

.star-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure canvas is behind hero content */
  pointer-events: none; /* Allows interaction with content above */
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 78%, #1f2937 90%);
  transition: background 0.5s ease;
}

.content {
  position: relative;
  z-index: 10;
}
/* Global styles */
body {
  font-family: 'Inter', sans-serif;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text color */
}
